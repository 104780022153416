.table thead th {
  padding: 8px 0px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  text-align: center;
}
.table tbody td {
  padding: 2px 0px;
  text-align: center;
}
.cpl-table thead th {
  padding: 8px 0px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  text-align: center;
}
.cpl-table tbody td {
  padding: 2px 0px;
  text-align: center;
}

.basicTable thead th {
  font-weight: bold;
  text-align: center;
  font-size: 95%;
}
.basicTable tbody td {
  padding: 8px 15px;
  text-align: center;
}

.intraStartTime .MuiInputBase-input {
  font-size: 14px;
  padding: 8.5px 5px;
}

.intraDept .PaperProps {
  max-height: 30;
}

.admin-table thead th {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}

.admin-table tbody td {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
  font-size: 15px;
}

.admin-input input {
  text-align: center;
  font-size: 15px;
}

.combo-table thead th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  font-weight: bold;
}

.combo-table tbody td {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
}

.admin-btn {
  color: black;
}

.dept-table thead th {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.dept-table tbody td {
  border-bottom: none;
}


.searchTable tbody td {
  border-bottom: none;
}

.dg-table thead th {
  padding: 3px 0px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  text-align: center;
}
.dg-table tbody td {
  padding: 4px 0px;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
}

.inputTableInquery1 th {
  border: 3px solid #00000090;
  width: 30%;
  padding: 2%;
  font-size: medium;
  font-family: "Noto Sans KR", sans-serif;
  background-color: #EAEAEA;
}

.inputTableInquery1 {
  width: 100%;
}
.inputTableInquery1 td {
  border: 3px solid #00000090;
  width: 70%;
  padding-left: 5%;
  font-family: "Noto Sans KR", sans-serif;
  background-color: #EAEAEA;
}


.inputTableInquery2 th {
  border: 3px solid #00000090;
  width: 30%;
  padding: 1%;
  font-size: medium;
  font-family: "Noto Sans KR", sans-serif;
}

.inputTableInquery2 {
  width: 100%;
}
.inputTableInquery2 td {
  border: 3px solid #00000090;
  width: 70%;
  padding: 1%;
  padding-left: 5%;
  font-family: "Noto Sans KR", sans-serif;
}

#mawbText {
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.file-table thead th {
  padding: 9px 8px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 15px;
}
.file-table tbody td {
  padding: 8px 8px;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
}